<template>
  <div
    :style="cssColorVars"
    class="a-tabs"
    role="tablist"
    tabindex="0"
    :aria-label="ariaLabel"
    @keydown.left.exact.stop="$emit('focus-next', currentFocusIdx - 1)"
    @keydown.right.exact.stop="$emit('focus-next', currentFocusIdx + 1)"
  >
    <button
      v-for="(tab, idx) in statusList"
      :id="`tab-${tab.value}`"
      :key="`tab-${idx}`"
      ref="tabs"
      :value="tab.value"
      class="tab"
      z-index="0"
      :aria-controls="tab.fullName"
      :aria-selected="String(isActive(tab))"
      role="tab"
      :tabindex="isActive(tab) ? 0 : -1"
      :class="[{ current: isActive(tab), tab }]"
      @click="changeTab($event, idx)"
    >
      <span
        :id="`tab-title-${tab.value}`"
        class="tab-title"
        z-index="-1"
        tabindex="-1"
      >
        {{ tab.label }}
      </span>
      <span
        v-if="showStatusCount"
        z-index="-1"
        class="tab-counter"
      >
        {{ tab.count < 99 ? tab.count : '99+' }}
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ATabs',
  props: {
    /**
     * An array of tab objects {value, label, count}
     */
    statusList: {
      type: Array,
      required: true,
    },
    /**
     * Shows a count next to the tab name
     */
    showStatusCount: Boolean,
    /**
     * Matching label will be selected
     */
    selected: [String, Number],
    /**
     * Adds a confirmation modal when transitioning between tabs
     */
    confirm: Boolean,
    /**
     * Title used in confirmation modal
     */
    confirmTitle: {
      type: String,
      default: '',
    },
    /**
     * Text content in confirmation modal
     */
    confirmText: {
      type: String,
      default: '',
    },
    highlightsColor: {
      type: String,
      default: '#004990',
    },
    ariaLabel: {
      type: String,
      default: '',
    },
    currentFocusIdx: {
      type: Number,
      default: 0,
    },
  },
  emits: ['focus-next', 'switch-tabs'],
  data() {
    return {
      currentStatus: '',
      currentEvent: null,
      destination: {},
      isConfirming: false,
    };
  },
  computed: {
    cssColorVars() {
      return {
        '--tab-border-bottom-active': `${this.highlightsColor}`,
        '--tab-border-bottom': `${this.highlightsColor}1A`,
        '--tab-counter': `${this.highlightsColor}`,
        '--tab-font-color': '#707070',
        '--tab-font-color-hover': `${this.highlightsColor}`,
        '--tab-font-color-active': `${this.highlightsColor}`,
      };
    },
  },
  created() {
    this.currentStatus = this.statusList.find((s) => s.idx === this.selected) || this.statusList[0];
  },
  methods: {
    isActive(status) {
      return status.idx === this.selected;
    },
    confirmChange() {
      if (this.isConfirming) {
        this.isConfirming = false;
        this.currentStatus = this.destination;
        /**
         * Triggers on tab change with the current tab
         */
        this.$emit('switch-tabs', this.currentEvent);
      }
    },
    changeTab(e, tab) {
      this.currentEvent = e;
      if (this.confirm) {
        this.destination = tab;
        this.isConfirming = true;
      } else {
        this.currentStatus = tab;
        this.$emit('switch-tabs', tab);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../global/scss/color_vars';
@import '../scss/mixins/flex_mixin';
@import '../scss/mixins/breakpoints';

.a-tabs {
  --tab-bg-color: transparent;

  @include flex-row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .tab {
    border-radius: 0;
    border: none;
    border-bottom: 4px solid var(--tab-border-bottom);
    display: flex;
    align-items: center;
    color: (var(--tab-font-color));
    background-color: var(--tab-bg-color);
    outline: none;
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    font-family: inherit;
    line-height: 32px;
    letter-spacing: 0.8px;
  }
  .tab:hover,
  .tab:hover:not(.current) .status-count {
    color: var(--tab-font-color-hover);
  }
  .tab.current {
    border-bottom: 4px solid var(--tab-border-bottom-active);
    color: var(--tab-font-color-active);
    font-weight: 900;
  }
  @media (max-width: 360px) {
    .tab {
      padding: 4px;
      width: 50px;
    }
  }
  @include screen('xs') {
    .tab {
      padding: 8px;
      width: 55px;
    }
  }
  @include screen('sm') {
    .tab {
      padding: 12px 6px 8px 6px;
    }
  }
  @media (min-width: $lg-min-width) {
    .tab {
      padding: 12px 8px 8px 8px;
    }
  }
  .tab-title {
    font-size: 24px;
  }
  .tab:focus,
  .tab-title {
    outline: none;
  }
  .tab:focus-visible > .tab-title {
    outline: var(--tab-font-color-active) auto 1px;
  }
  .tab-counter {
    background-color: var(--tab-counter);
    color: white;
    margin-left: 4px;
    border-radius: 12.5px;
    width: 42px;
    height: 24px;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 6px;
    box-sizing: border-box;
  }
}
</style>
